import type { ColumnsType } from '@iib/pandora-box';
import { ListLayout, useTranslation } from '@iib/pandora-box';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NotificationTemplate } from '../../../types';
import { TestID, convertSortOrder } from '../../../utils';
import { useListTemplates } from '../hooks';
import * as S from './styles';

const ITEMS_PER_PAGE = 15;

export const NotificationList: FC = (): JSX.Element => {
  const [numberOfItems, setNumberOfItems] = useState<number>(0);
  const [items, setItems] = useState<Array<NotificationTemplate & { key: string }>>([]);
  const [query, setQuery] = useState<string | undefined>();
  const [sort, setSort] = useState<Record<string, 'ASC' | 'DESC'>>();
  const { performAction, loading } = useListTemplates();
  const history = useHistory();
  const { t } = useTranslation();

  const fetchData = async (page = 1, searchQuery?: string, sorter?: Record<string, 'ASC' | 'DESC'>) => {
    const data = await performAction({ page, itemsPerPage: ITEMS_PER_PAGE, query: searchQuery, sort: sorter });
    setNumberOfItems(data.numberOfItems);
    setItems(data?.items.map((item) => ({ ...item, key: String(item.id) })));
  };

  const onSearch = (value: any) => {
    setQuery(value);
    fetchData(1, value, sort);
  };

  const columns: ColumnsType<NotificationTemplate> = [
    {
      title: t('template_name'),
      dataIndex: 'identifier',
      key: 'identifier',
      width: '300px',
    },
    {
      title: t('subject'),
      dataIndex: 'subject',
      key: 'subject',
      width: '450px',
    },
  ];

  const handleTableChange = (page: number, sortData: any) => {
    const field = sortData?.field;
    const sorter = sortData?.order && sortData?.field ? { [field]: convertSortOrder(sortData?.order) } : undefined;
    setSort(sorter);
    fetchData(page, query, sorter);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <S.ViewWrapper data-testid={TestID.NotificationList}>
      <ListLayout
        hideSearch
        width="100%"
        tableWidth="100%"
        searchPlaceHolder={t('search_table')}
        onSearch={onSearch}
        columns={columns}
        onRow={(item) => ({
          style: { cursor: 'pointer' },
          onClick: () => history.push(`/notifications/templates/${item.id}/detail`),
        })}
        dataSource={items}
        loading={loading}
        pageSize={ITEMS_PER_PAGE}
        total={numberOfItems}
        onTableChange={({ current }, _filters, sortData) => handleTableChange(current || 1, sortData)}
      />
    </S.ViewWrapper>
  );
};
