import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CorrectionsList } from './corrections-list';
import * as S from './styles';
import { CorrectionsDetail } from './correction-detail';
import { MapProvider } from '@iib/pandora-box';

export default function Component(): JSX.Element {
  return (
    <S.Wrapper>
      <MapProvider>
        <Switch>
          <Route path="/residentialArea/Corrections" exact component={CorrectionsList} />
          <Route path="/residentialArea/Corrections/detail/:id" component={CorrectionsDetail} />
        </Switch>
      </MapProvider>
    </S.Wrapper>
  );
}
