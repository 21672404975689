import { useAuth0 } from '@auth0/auth0-react';
import { Layout as PandoraLayout, useTranslation } from '@iib/pandora-box';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import modules from '../modules';
import auth from '../services/auth';

interface Props {
  children: any;
  moduleConfig?: any;
}

const languages = ['en', 'de'];

export default function ({ moduleConfig, children }: Props): ReactElement {
  const { logout, user } = useAuth0();
  const { push } = useHistory();
  const { t } = useTranslation();
  const [initialRenderComplete, setInitialRenderComplete] = useState<boolean>(false);

  useEffect(() => {
    setInitialRenderComplete(true);
  }, []);

  if (!user || !initialRenderComplete) return <></>;

  return (
    <PandoraLayout
      sidebarWidth="250px"
      sidebarWidthCollapsed="80px"
      footerStyle="sidebar"
      sidebarContent={moduleConfig?.navigation ? moduleConfig?.navigation : true}
      languageSwitch
      languages={languages}
      user={{ avatar: user.picture, name: user.name || '', email: user.email || '' }}
      userMenuPlacement="bottomLeft"
      logout={(): void => logout({ logoutParams: { returnTo: window.location.origin } })}
      leftMenuLinks={modules
        .filter((module) => auth.hasPermission(module.permissions))
        .map((module) => ({ label: t(module.title), onClick: (link) => push(link.path), path: module.startPage ? module.startPage : `/${module.name}` }))}
      showFooter
    >
      {children}
    </PandoraLayout>
  );
}
