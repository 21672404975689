export const UserApiUrls = {
  list: '/users/user',
  roles: '/users/role',
  create: '/users/user',
  update: (id: string): string => `/users/user/auth0|${id}`,
  getById: (id: string): string => `/users/user/auth0|${id}`,
  delete: (id: string): string => `/users/user/auth0|${id}`,
  reactivate: (id: string): string => `/users/user/auth0|${id}/reactivate`,
};

export const CustomerApiUrls = {
  list: 'customers/customers',
  getById: (id: number): string => `/customers/customers/${id}`,
};

export const NotificationApiUrls = {
  list: '/notifications/template',
  getById: (id: number): string => `/notifications/template/${id}`,
  update: (id: number): string => `/notifications/template/${id}`,
  deleteById: (id: number): string => `/notifications/template/${id}`,
  create: '/notifications/template',
};

export const SGVApiUrls = {
  list: '/standard-ground-value/source',
  parametersList: 'standard-ground-value/source-parameter',
  getById: (id: string): string => `/standard-ground-value/source/${id}`,
  getSourceParameterById: (id: string): string => `/standard-ground-value/source-parameter/${id}`,
  deleteById: (id: string): string => `/standard-ground-value/source-parameter/${id}/delete`,
};

export const CorrectionsApiUrls = {
  list: '/residential-area/corrections',
  getById: (id: string): string => `residential-area/corrections/${id}`,
  deleteById: (id: string): string => `residential-area/corrections/${id}/delete`,
  approveById: (id: string): string => `residential-area/corrections/${id}/resolve`,
  updateAnswerById: (id: string): string => `residential-area/corrections/${id}/answer`,
};

export const RatingsApiUrls = {
  categories: '/residential-area/ratings/categories',
  list: '/residential-area/ratings',
  getById: (id: string): string => `residential-area/ratings/${id}`,
  deleteById: (id: string): string => `residential-area/ratings/${id}/delete`,
  approveById: (id: string): string => `residential-area/ratings/${id}/approve`,
};
