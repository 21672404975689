import { DetailLayout, ShouldRender, useTranslation } from '@iib/pandora-box';
import { List, Tooltip } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { Customer, Role, User } from '../../../types';
import { COLOR, TestID, notEmpty } from '../../../utils';
import { useGetCustomer, useGetUser, useListUserRoles } from '../hooks';
import * as S from './styles';
import { UserActions } from '../../../components/common';
import classNames from 'classnames';

export const UserDetail: FC = (): JSX.Element => {
  const {
    location: { pathname },
  } = useHistory();
  const id = pathname.split('/')[3];
  const [user, setUser] = useState<User | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [customers, setCustomers] = useState<Array<Customer>>([]);
  const [roles, setRoles] = useState<Array<Role>>([]);
  const { performAction: getUser, loading: loadingUser } = useGetUser();
  const { performAction: getCustomer, loading: loadingCustomer } = useGetCustomer();
  const { performAction: getRoles } = useListUserRoles();
  const { t } = useTranslation();

  const fetchUser = async () => {
    const fetchedUser = await getUser({ id });

    setUser(fetchedUser);

    if (!fetchedUser) return;

    const fetchedCustomer = fetchedUser?.customer ? await getCustomer({ id: fetchedUser?.customer }) : null;
    setCustomer(fetchedCustomer);

    const fetchedCustomersPromise = fetchedUser.customers?.map((customerId) => getCustomer({ id: customerId }));
    const fetchedCustomers = fetchedCustomersPromise ? await Promise.all(fetchedCustomersPromise) : [];
    setCustomers(fetchedCustomers.filter(notEmpty));

    const fetchedRoles = await getRoles();
    setRoles(fetchedRoles);
  };

  useEffect(() => {
    fetchUser();
  }, [id]);

  return (
    <S.ViewWrapper data-testid={TestID.UserDetail}>
      {user && (
        <>
          <DetailLayout
            width="100%"
            columns={2}
            loading={loadingUser || loadingCustomer}
            Loader={<Loading />}
            header={{
              title: t('user_data'),
              rightButton: <UserActions id={id} blocked={user.blocked} onOk={fetchUser} />,
            }}
            record={user}
            fields={{
              givenName: { label: t('given_name'), render: (value) => <S.FieldItem>{value}</S.FieldItem> },
              familyName: { label: t('family_name'), render: (value) => <S.FieldItem>{value}</S.FieldItem> },
              email: { label: t('email'), render: (value) => <S.FieldItem>{value}</S.FieldItem> },
              phoneNumber: { label: t('phone_number'), render: (value) => <S.FieldItem>{value}</S.FieldItem> },
              customers: {
                label: t('can_access_customers'),
                span: 2,
                render: () => (
                  <List
                    dataSource={customers}
                    renderItem={(item: Customer) => (
                      <Tooltip placement="left" title={`ID: ${item.id}`}>
                        <S.ListItem key={item.id}>
                          {item.companyName} {item.city}
                        </S.ListItem>
                      </Tooltip>
                    )}
                  />
                ),
              },
              customer: {
                label: t('belongs_to_customer'),
                span: 2,
                render: () => (
                  <ShouldRender if={customer?.id !== undefined}>
                    <Tooltip placement="left" title={`ID: ${customer?.id}`}>
                      <S.ListItem>{customer?.companyName}</S.ListItem>
                    </Tooltip>
                  </ShouldRender>
                ),
              },
              roles: {
                label: t('roles_c'),
                span: 2,
                render: (userRoles) => (
                  <>
                    {roles?.map((r) => (
                      <S.RoleList key={r?.id}>
                        <S.RoleCheckbox checked={!!userRoles.find((role) => role.id === r.id)}>{r.description}</S.RoleCheckbox>
                      </S.RoleList>
                    ))}
                  </>
                ),
              },
              affiliate: {
                label: t('affiliate_c'),
                span: 1,
              },
              blocked: {
                label: t('user_active'),
                span: 1,
                render: (blocked) => (
                  <S.LightWrapper>
                    <S.Light className={classNames({ 'user-active': !blocked, 'user-blocked': blocked })} color={!blocked ? COLOR.GREEN : COLOR.RED} />
                  </S.LightWrapper>
                ),
              },
              notes: {
                label: t('register_tools'),
                span: 2,
              },
            }}
          />
        </>
      )}
    </S.ViewWrapper>
  );
};
