import { faFileInvoice, faUser } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Navigation, NavigationItem } from '../../components/navigation';
import ModuleInterface from '../../types/general';
import Corrections from './features/Corrections';
import NewCorrections from './features/NewCorrections';
import Ratings from './features/Ratings';

const config: ModuleInterface = {
  name: 'residentialArea',
  root: 'residentialArea/corrections',
  startPage: '/residentialArea/corrections',
  title: 'residential_areas',
  permissions: ['residentialArea:admin'],
  navigation: [
    <Navigation label="residential_areas" key="navigation">
      <NavigationItem label="new_corrections" icon={faUser} path="/residentialArea/newCorrections" />
      <NavigationItem label="all_corrections" icon={faUser} path="/residentialArea/corrections" />
      <NavigationItem label="all_ratings" icon={faFileInvoice} path="/residentialArea/ratings" />
    </Navigation>,
  ],
  routes: [
    {
      path: '/newCorrections',
      component: NewCorrections,
    },
    {
      path: '/corrections',
      component: Corrections,
    },
    {
      path: '/ratings',
      component: Ratings,
    },
  ],
};
export default config;
