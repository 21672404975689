import { useTranslation, confirm, AiFillExclamationCircle } from '@iib/pandora-box';
import React from 'react';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDeleteUser, useReactivateUser } from '../../modules/users/hooks';

import * as S from './styles';

type Props = {
  id?: string;
  blocked?: boolean;
  isEdit?: boolean;
  onOk?: () => any;
};

export const UserActions: FC<Props> = ({ id, blocked, isEdit, onOk }) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { performAction: deleteUser } = useDeleteUser();
  const { performAction: reactivateUser } = useReactivateUser();

  if (!id) return <></>;

  const showDeactivateConfirm = () => {
    confirm({
      title: t('delete_user_heading'),
      icon: <AiFillExclamationCircle />,
      okType: 'danger',
      cancelText: t('cancel'),
      async onOk() {
        const response = await deleteUser({ id });
        if (!!response && 'success' in response && response.success && onOk) onOk();
      },
    });
  };

  const showReactivateConfirm = () => {
    confirm({
      title: t('reactivate_user_heading'),
      icon: <AiFillExclamationCircle />,
      okType: 'primary',
      cancelText: t('cancel'),
      async onOk() {
        const response = await reactivateUser({ id });
        if (!!response && 'success' in response && response.success && onOk) onOk();
      },
    });
  };

  const handleBlocked = () => {
    if (blocked) showReactivateConfirm();
    else showDeactivateConfirm();
  };

  const handleRoute = () => {
    if (isEdit) push(`/users/detail/${id}`);
    else push(`/users/edit/${id}`);
  };

  return (
    <>
      <S.UserActions.Button delete={!blocked} onClick={() => handleBlocked()}>
        {blocked ? t('reactivate') : t('deactivate')}
      </S.UserActions.Button>
      <S.UserActions.Button onClick={() => handleRoute()}>{isEdit ? t('cancel') : t('edit_user')}</S.UserActions.Button>
    </>
  );
};
