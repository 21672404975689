import type { ColumnsType } from '@iib/pandora-box';
import { ListLayout, useTranslation } from '@iib/pandora-box';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { User, WithRolesOption } from '../../../types';
import { COLOR, TestID, convertSortOrder } from '../../../utils';
import { useListUsers } from '../hooks';
import * as S from './styles';

const ITEMS_PER_PAGE = 15;

export const UserList: FC = (): JSX.Element => {
  const [numberOfItems, setNumberOfItems] = useState<number>(0);
  const [items, setItems] = useState<Array<User & { key: string }>>([]);
  const [query, setQuery] = useState<string | undefined>();
  const [sort, setSort] = useState<Record<string, 'ASC' | 'DESC'>>();
  const { performAction, loading } = useListUsers();
  const history = useHistory();
  const { t } = useTranslation();

  const fetchData = async (page = 1, searchQuery?: string, sorter?: Record<string, 'ASC' | 'DESC'>) => {
    const data = await performAction({ page, itemsPerPage: ITEMS_PER_PAGE, query: searchQuery, sort: sorter, includeDeleted: true, withRoles: WithRolesOption.EXTERNAL });
    setNumberOfItems(data.numberOfItems);
    setItems(data?.items.map((item) => ({ ...item, key: item.id })));
  };

  const onSearch = (value: any) => {
    setQuery(value);
    fetchData(1, value, sort);
  };

  const columns: ColumnsType<User> = [
    {
      title: 'Access',
      align: 'center',
      render: (_, item) => {
        let color = '#ccc';
        if (item.email.includes('@iib-institut.de') || (item.roles?.length > 0 && item.customers?.length > 0)) {
          color = COLOR.GREEN;
        } else if (item.roles.length > 0 || item.customers?.length > 0) {
          color = COLOR.YELLOW;
        } else {
          color = COLOR.RED;
        }

        return (
          <S.LightWrapper>
            <S.Light color={color} />
          </S.LightWrapper>
        );
      },
      width: '50px',
    },
    {
      title: t('given_name'),
      align: 'center',
      dataIndex: 'givenName',
      key: 'givenName',
      width: '150px',
    },
    {
      title: t('family_name'),
      align: 'center',
      dataIndex: 'familyName',
      key: 'familyName',
      width: '150px',
    },
    {
      title: t('email_c'),
      align: 'center',
      dataIndex: 'email',
      key: 'email',
      width: '250px',
      sorter: true,
    },
    {
      title: t('user_active'),
      align: 'center',
      dataIndex: 'blocked',
      key: 'blocked',
      width: '150px',
      render: (blocked) => (
        <S.LightWrapper>
          <S.Light color={!blocked ? '#6aca37' : '#ca3737'} />
        </S.LightWrapper>
      ),
    },
  ];

  const handleTableChange = (page: number, sortData: any) => {
    const field = sortData?.field;
    const sorter = sortData?.order && sortData?.field ? { [field]: convertSortOrder(sortData?.order) } : undefined;
    setSort(sorter);
    fetchData(page, query, sorter);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <S.ViewWrapper data-testid={TestID.UserList}>
      <ListLayout
        width="100%"
        tableWidth="100%"
        searchPlaceHolder={t('search_table')}
        onSearch={onSearch}
        columns={columns}
        onRow={(item) => ({
          style: { cursor: 'pointer' },
          onClick: () => history.push(`/users/detail/${item.id.split('|')[1]}`),
        })}
        dataSource={items}
        loading={loading}
        pageSize={ITEMS_PER_PAGE}
        total={numberOfItems}
        onTableChange={({ current }, _filters, sortData) => handleTableChange(current || 1, sortData)}
      />
    </S.ViewWrapper>
  );
};
