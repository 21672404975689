import type { ColumnsType } from '@iib/pandora-box';
import { ListLayout, useTranslation } from '@iib/pandora-box';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Rating } from '../../../types';
import { COLOR, convertSortOrder } from '../../../utils';
import { useListRatings } from '../hooks';

import * as S from './styles';

const ITEMS_PER_PAGE = 15;

export const RatingsList: FC = () => {
  const history = useHistory();
  const [numberOfItems, setNumberOfItems] = useState<number>(0);
  const [items, setItems] = useState<Array<Rating & { key: string }>>([]);
  const [query, setQuery] = useState<string | undefined>();
  const [sort, setSort] = useState<Record<string, 'ASC' | 'DESC'>>();
  const { performAction, loading } = useListRatings();
  const { t } = useTranslation();

  const fetchData = async (page = 1, searchQuery?: string, sorter?: Record<string, 'ASC' | 'DESC'>) => {
    const data = await performAction({ page, itemsPerPage: ITEMS_PER_PAGE, query: searchQuery, sort: sorter });
    setNumberOfItems(data.numberOfItems);
    setItems(data?.items.map((item) => ({ ...item, key: String(item.id) })));
  };

  const onSearch = (value: any) => {
    setQuery(value);
    fetchData(1, value, sort);
  };

  const handleTableChange = (page: number, sortData: any) => {
    const field = sortData?.field;
    const sorter = sortData?.order && sortData?.field ? { [field]: convertSortOrder(sortData?.order) } : undefined;
    setSort(sorter);
    fetchData(page, query, sorter);
  };

  const columns: ColumnsType<Rating> = [
    {
      title: t('created_at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '150px',
      sorter: true,
      render: (createdAt) => `${moment(createdAt).format('DD.MM.YYYY hh:mm')}${t('hour')}`,
    },
    {
      title: t('confirmed'),
      dataIndex: 'isApproved',
      key: 'isApproved',
      width: '150px',
      render: (isApproved) => {
        return <S.Light color={isApproved ? COLOR.GREEN : COLOR.RED} />;
      },
    },
    {
      title: t('on_site_assessment'),
      dataIndex: 'isOnSiteRating',
      key: 'isOnSiteRating',
      width: '250px',
      render: (isOnSiteRating) => {
        return <S.Light color={isOnSiteRating ? COLOR.GREEN : COLOR.RED} />;
      },
    },
    {
      title: t('email_c'),
      dataIndex: 'userEmail',
      key: 'userEmail',
      width: '250px',
      render: (userEmail) => {
        return userEmail || 'N/A';
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ListLayout
      width="100%"
      tableWidth="100%"
      searchPlaceHolder={t('search_table')}
      onSearch={onSearch}
      columns={columns}
      dataSource={items}
      loading={loading}
      pageSize={ITEMS_PER_PAGE}
      total={numberOfItems}
      onRow={(item) => ({
        style: { cursor: 'pointer' },
        onClick: () => history.push(`/residentialArea/ratings/detail/${item.id}`),
      })}
      onTableChange={({ current }, _filters, sortData) => handleTableChange(current || 1, sortData)}
    />
  );
};
