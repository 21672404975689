import { PALETTE } from '@iib/pandora-box';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const anthracite = PALETTE.GRAY[700];

export const NavLink = styled(Link)`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin-left: 20px;
  gap: 10px;
`;

export const NavList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 5px 0px;
  width: 100%;
`;

export const NavHeading = styled.h6`
  text-align: start;
  width: 90%;
  margin: 30px auto;
  margin-left: 25px;
  margin-bottom: 30px;
`;
