import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faThLarge,
  faChevronUp,
  faChevronDown,
  faTimesCircle,
  faCheckCircle,
  faQuestionCircle,
  faCaretRight,
  faCaretDown,
  faTimes,
  faFileImport,
  faCogs,
  faBath,
  faShare,
} from '@fortawesome/free-solid-svg-icons';
import { faJira } from '@fortawesome/free-brands-svg-icons';

library.add(faBars);
library.add(faThLarge);
library.add(faChevronUp);
library.add(faChevronDown);
library.add(faTimesCircle);
library.add(faCheckCircle);
library.add(faQuestionCircle);
library.add(faCaretRight);
library.add(faCaretDown);
library.add(faTimes);
library.add(faFileImport);
library.add(faCogs);
library.add(faBath);
library.add(faShare);
library.add(faJira as any);
