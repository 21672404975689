export const sourceIdentifiers = [
  { label: 'Boris Deutschland', value: 'BorisDeutschland' },
  { label: 'Hessen', value: 'Hessen' },
  { label: 'Schwandorf', value: 'KreisSchwandorf' },
  { label: 'Schleswig-Holstein', value: 'SchleswigHolstein' },
  { label: 'Saarland', value: 'Saarland' },
  { label: 'Mecklenburg-Vorpommern', value: 'MecklenburgVorpommern' },
  { label: 'Brandenburg', value: 'Brandenburg' },
  { label: 'Sachsen', value: 'Sachsen' },
  { label: 'Heitersheim', value: 'Heitersheim' },
  { label: 'Schopfheim', value: 'Schopfheim' },
  { label: 'Weil am Rhein', value: 'WeilAmRhein' },
  { label: 'Donau-Heuberg', value: 'DonauHeuberg' },
  { label: 'Lörrach', value: 'Loerrach' },
  { label: 'BayernBezahlt', value: 'BayernBezahlt' },
  { label: 'BayernAtlas', value: 'BavariaAtlas' },
  { label: 'Sachsen-Anhalt', value: 'SachsenAnhalt' },
  { label: 'München', value: 'Muenchen' },
  { label: 'Freiburg', value: 'Freiburg' },
  { label: 'Mannheim', value: 'Mannheim' },
  { label: 'Landshut', value: 'Landshut' },
  { label: 'Traunstein', value: 'Traunstein' },
  { label: 'Nordrhein-Westphalen', value: 'Nordrhein-Westphalen' },
  { label: 'Hamburg', value: 'Hamburg' },
  { label: 'Thüringen', value: 'Thueringen' },
  { label: 'Berlin', value: 'Berlin' },
  { label: 'BorisBadenWuerttemberg', value: 'BorisBadenWuerttemberg' },
  { label: 'Rheinland-Pfalz', value: 'RheinlandPfalz' },
  { label: ' - ', value: null },
];

export const ratingCategories = [
  {
    id: '0',
    title: 'Gebäude',
    ratingAttributes: [
      {
        id: 'buildingType',
        title: 'building_type',
        type: 'multiple',
        display: 'title',
        options: [
          {
            title: 'Freistehende Eigenheime, Villen (5)',
            points: 5,
          },
          {
            title: 'Hausbebauung 2-6 Wohneinheiten (3)',
            points: 3,
          },
          {
            title: 'Historische Gebäude + Stadthäuser 7-12 Wohneinheiten (4)',
            points: 4,
          },
          {
            title: 'Geschoßwohnungsbau > 12 Wohneinheiten (2)',
            points: 2,
          },
          {
            title: 'Mischgebiete / Innenstadt (1)',
            points: 1,
          },
          {
            title: 'Hochhäuser / Großsiedlung (1)',
            points: 1,
          },
          {
            title: 'Hausbebauung 7-12 Wohneinheiten (2)',
            points: 2,
          },
          {
            title: 'Doppelhaushälften und Reihenhäuser (3)',
            points: 3,
          },
          {
            title: 'Dorfgebiet (2)',
            points: 2,
          },
          {
            title: 'Weiler (1)',
            points: 1,
          },
          {
            title: 'Ferienhausgebiet (2)',
            points: 2,
          },
        ],
      },
      {
        id: 'buildYearClassification',
        title: 'year_class',
        type: 'multiple',
        display: 'title',
        options: [
          {
            title: 'bis 1918 / historisch (5)',
            points: 5,
          },
          {
            title: 'bis 1918 (2)',
            points: 2,
          },
          {
            title: '1919 - 1957 (1)',
            points: 1,
          },
          {
            title: '1958 - 1978 (2)',
            points: 2,
          },
          {
            title: '1979 - 1994 (3)',
            points: 3,
          },
          {
            title: '1995 - 2007 (4)',
            points: 4,
          },
          {
            title: 'ab 2008 (5)',
            points: 5,
          },
          {
            title: 'aktuelles Neubauquartier (5)',
            points: 5,
          },
        ],
      },
      {
        id: 'uniqueArea',
        title: 'unique_location',
        display: 'title',
        type: 'select',
        options: [
          {
            title: 'historischer Altstadtkern',
            points: 5,
          },
          {
            title: 'unverbaubare Hanglage',
            points: 5,
          },
          {
            title: 'unverbaubare Wasserlage',
            points: 5,
          },
          {
            title: 'architektonisch äußerst hochwertiges Quartier',
            points: 5,
          },
          {
            title: 'Denkmalschutzquartier',
            points: 5,
          },
          {
            title: 'unverbaubare Randlage',
            points: 5,
          },
        ],
      },
      {
        id: 'buildingDensity',
        title: 'building_density',
        type: 'range',
        display: 'points',
        options: [
          {
            title: 'lockere Bebauungsdichte (EFH/kleine MFH GFZ: 0,1 -0,3)',
            points: 5,
          },
          {
            title: 'mittlere Bebauungsdichte (EFH/kleine MFH GFZ: 0,4 - 0,6)',
            points: 4,
          },
          {
            title: 'mittlere Bebauungsdichte (MFH GFZ: 0,5 - 0,9)',
            points: 3,
          },
          {
            title: 'hohe Bebauungsdichte (historische Altstadt GFZ: 3,0 - 4,5)',
            points: 2,
          },
          {
            title: 'hohe Bebauungsdichte (Blockbeb. /Hochhäuser/City GFZ: 1,0 - 4,0)',
            points: 1,
          },
        ],
      },
      {
        id: 'renovationClassification',
        title: 'sanitation_condition',
        type: 'range',
        display: 'title',
        options: [
          {
            title: '>60% o. Neubau',
            points: 5,
          },
          {
            title: '40 - 60%',
            points: 3,
          },
          {
            title: '< 40%',
            points: 1,
          },
        ],
      },
      {
        id: 'buildingConditionAndLook',
        title: 'building_condition',
        type: 'range',
        display: 'title',
        options: [
          {
            title: 'top',
            points: 5,
          },
          {
            title: 'sehr gut',
            points: 4,
          },
          {
            title: 'gut',
            points: 3,
          },
          {
            title: 'durchschnittlich',
            points: 2,
          },
          {
            title: 'schlecht',
            points: 1,
          },
        ],
      },
    ],
  },
  {
    id: '1',
    title: 'Infrastruktur',
    ratingAttributes: [
      {
        id: 'publicTransport',
        title: 'rating_opnv',
        type: 'range',
        display: 'points',
        options: [
          {
            title: 'mehrere Haltestellen mit sehr guter Anbindung im Quartier',
            points: 5,
          },
          {
            title: 'Haltestelle mit guter Anbindung in max. 5 Gehminuten vom Quartier',
            points: 4,
          },
          {
            title: 'Haltestelle mit guter Anbindung in max. 10 Gehminuten vom Quartier',
            points: 3,
          },
          {
            title: 'Haltestelle mit guter Anbindung in max. 15 Gehminuten vom Quartier',
            points: 2,
          },
          {
            title: 'keine Haltestelle in der Umgebung',
            points: 1,
          },
        ],
      },
      {
        id: 'parking',
        title: 'rating_parking',
        type: 'range',
        display: 'points',
        options: [
          {
            title: 'immer vorhanden ',
            comment: 'Unmittelbar am Quartier vorhanden',
            points: 5,
          },
          {
            title: 'eher vorhanden',
            comment: 'An den umliegenden Quartieren vorhanden',
            points: 3,
          },
          {
            title: 'eher nicht vorhanden',
            points: 1,
          },
        ],
      },
      {
        id: 'infrastructureReachability',
        title: 'rating_car_accessibility',
        type: 'range',
        display: 'points',
        options: [
          {
            title: 'bis 10 min (in Großstädten bis 5 min)',
            points: 5,
          },
          {
            title: 'bis 20 min (in Großstädten bis 10 min)',
            points: 2,
          },
          {
            title: 'bis 30 min (in Großstädten bis 15 min)',
            points: 1,
          },
        ],
      },
      {
        id: 'generalSupply',
        title: 'rating_close_supply',
        type: 'range',
        display: 'points',
        options: [
          {
            title: 'Zwei bis drei Geschäfte und Schulen im 1-km-Umkreis',
            points: 5,
            comment: 'Bäcker oder Metzger sollte es zumindest sein und Kita, Grundschule oder weiterführende Schule',
          },
          {
            title: 'Zwei bis drei Geschäfte oder Schulen im 1-km-Umkreis',
            points: 4,
            comment: 'Bäcker oder Metzger sollte es zumindest sein und Kita, Grundschule oder weiterführende Schule',
          },
          {
            title: 'Mindestens ein Geschäft oder eine Schule im 1-km-Umkreis',
            points: 3,
            comment: 'Bäcker oder Metzger sollte es zumindest sein',
          },
          {
            title: 'Mindestens ein Geschäft oder eine Schule im 1,5-km-Umkreis',
            points: 2,
          },
          {
            title: 'keine Geschäfte bzw. Schulen im näheren Umkreis',
            points: 1,
          },
        ],
      },
      {
        id: 'centrality',
        title: 'rating_centrality',
        type: 'range',
        display: 'title',
        options: [
          {
            title: 'sehr zentral',
            points: 5,
          },
          {
            title: 'eher zentral',
            points: 3,
          },
          {
            title: 'eher dezentral',
            points: 2,
          },
          {
            title: 'sehr dezentral',
            points: 1,
          },
        ],
      },
    ],
  },
  {
    id: '2',
    title: 'Umgebung',
    ratingAttributes: [
      {
        id: 'socialQuality',
        title: 'rating_vacancy',
        type: 'range',
        display: 'title',
        options: [
          {
            title: 'sehr gut',
            points: 5,
          },
          {
            title: 'gut',
            points: 4,
          },
          {
            title: 'durchschnittlich',
            points: 3,
          },
          {
            title: 'kritisch',
            points: 2,
          },
          {
            title: 'sehr kritisch',
            points: 1,
          },
        ],
      },
      {
        id: 'environmentalQuality',
        title: 'rating_district',
        type: 'range',
        display: 'title',
        options: [
          {
            title: 'sehr viel Grün',
            points: 5,
          },
          {
            title: 'viel Grün',
            points: 4,
          },
          {
            title: 'durchschnittlich',
            points: 3,
          },
          {
            title: 'wenig Grün',
            points: 2,
          },
          {
            title: 'kein Grün',
            points: 1,
          },
        ],
      },
      {
        id: 'noiseQuality',
        title: 'rating_noise',
        type: 'range',
        display: 'title',
        options: [
          {
            title: 'sehr leise',
            points: 5,
          },
          {
            title: 'leise',
            points: 4,
          },
          {
            title: 'durchschnittlich',
            points: 3,
          },
          {
            title: 'laut',
            points: 2,
          },
          {
            title: 'sehr laut',
            points: 1,
          },
        ],
      },
      {
        id: 'smellQuality',
        title: 'rating_odor',
        type: 'range',
        display: 'title',
        options: [
          {
            title: 'nie',
            points: 5,
          },
          {
            title: 'eigentlich nie',
            points: 4,
          },
          {
            title: 'manchmal',
            points: 3,
          },
          {
            title: 'häufig',
            points: 2,
          },
          {
            title: 'immer',
            points: 1,
          },
        ],
      },
      {
        id: 'imageQuality',
        title: 'rating_neighborhood',
        type: 'range',
        display: 'title',
        options: [
          {
            title: 'top',
            points: 5,
          },
          {
            title: 'sehr gut',
            points: 4,
          },
          {
            title: 'gut',
            points: 3,
          },
          {
            title: 'durchschnittlich',
            points: 2,
          },
          {
            title: 'schlecht',
            points: 1,
          },
        ],
      },
    ],
  },
];

export enum TestID {
  UserList = 'user-list',
  UserDetail = 'user-detail',
  UserCreate = 'user-create',
  NotificationList = 'notification-list',
  EmailDesignModal = 'email-design-modal',
  CorrectionsStatusSelect = 'corrections-status-select',
}

export const templateTypeLabels: Record<string, string> = {
  aws_ses: 'AWS SES',
  smtp: 'SMTP',
};

export const COLOR = {
  GREEN: '#6aca37',
  YELLOW: '#cac337',
  RED: '#ca3737',
};

export const defaultAttribution = `Copyright(C) ${new Date().getFullYear()} by <a href='http://www.iib-institut.de/impressum/' title='Admin UI' onclick='window.open(this.href); return false;'>iib-institut.de</a> © <a href='http://www.openstreetmap.org/' target='_blank'>OpenStreetMap</a> contributors / Lizenz: <a href='http://opendatacommons.org/licenses/odbl/' target='_blank'>ODbL</a>`;

export enum CorrectionStatus {
  REPORTED = 'gemeldet',
  IN_REVIEW = 'in Prüfung',
  RESOLVED = 'geprüft',
}

export const MAP_STYLE = 'iib-iib-wlrast-x4ra';
