import { useContext, useState } from 'react';
import { ApiClient } from '../../../services/api';
import { CustomerApiUrls, getAuthHeaders } from '../../../utils';
import { Customer, PaginatedList } from '../../../types';
import { useTranslation, PandoraContext } from '@iib/pandora-box';

type Params = {
  query?: string;
};

type HookReturnType = {
  performAction: (params: Params) => Promise<PaginatedList<Customer>>;
  loading: boolean;
};

export const useGetAllCustomers = (): HookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCustomers, setTotalCustomers] = useState<number>();
  const { t } = useTranslation();
  const { messageApi } = useContext(PandoraContext);

  const performGetAllCustomers = async ({ query }: Params): Promise<PaginatedList<Customer>> => {
    try {
      setLoading(true);
      let total = totalCustomers;
      if (!total) {
        const {
          data: { numberOfItems },
        } = await ApiClient.get(CustomerApiUrls.list, { headers: getAuthHeaders(), params: { query } });
        setTotalCustomers(numberOfItems);
        total = numberOfItems;
      }

      const { data } = await ApiClient.get(CustomerApiUrls.list, { headers: getAuthHeaders(), params: { query, itemsPerPage: total } });
      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
      messageApi?.error(t('api_error_generic'));
      return { page: 1, items: [], numberOfItems: 0, numberOfPages: 0 };
    }
  };
  return { loading, performAction: performGetAllCustomers };
};
