import { useContext, useState } from 'react';
import { ApiClient } from '../../../services/api';
import { NotificationApiUrls, getAuthHeaders } from '../../../utils';
import { NotificationTemplate, PaginatedList } from '../../../types';
import { useTranslation, PandoraContext } from '@iib/pandora-box';

type Params = {
  page?: number;
  itemsPerPage?: number;
  sort?: Record<string, 'ASC' | 'DESC'>;
  sortOrder?: string;
  filter?: Record<string, any>[];
  query?: string;
};

type HookReturnType = {
  performAction: (params: Params) => Promise<PaginatedList<NotificationTemplate>>;
  loading: boolean;
};

export const useListTemplates = (): HookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { messageApi } = useContext(PandoraContext);

  const performListTemplates = async (params: Params): Promise<PaginatedList<NotificationTemplate>> => {
    try {
      setLoading(true);

      const { data } = await ApiClient.get(NotificationApiUrls.list, { headers: getAuthHeaders(), params });
      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
      messageApi?.error(t('api_error_generic'));
      return { page: 1, items: [], numberOfItems: 0, numberOfPages: 0 };
    }
  };
  return { loading, performAction: performListTemplates };
};
