import { useContext, useState } from 'react';
import { ApiClient } from '../../../services/api';
import { UserApiUrls, getAuthHeaders } from '../../../utils';
import { User, MaybePromise } from '../../../types';
import { PandoraContext, useTranslation } from '@iib/pandora-box';

type Body = {
  givenName?: string;
  familyName?: string;
  email?: string;
  phoneNumber?: string;
  roles?: string[];
  customer?: number;
  customers?: number[];
  affiliate?: string;
  notes?: string;
};

type Props = {
  body: Body;
  id: string;
  onSuccess?: (updated: User) => MaybePromise<void>;
  onError?: (error: any) => MaybePromise<void>;
};
type HookReturnType = {
  performAction: (props: Props) => Promise<User | null>;
  loading: boolean;
};

export const useUpdateUser = (): HookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { messageApi } = useContext(PandoraContext);

  const performUpdateUser = async ({ id, body, onSuccess, onError }: Props): Promise<User | null> => {
    try {
      setLoading(true);
      const { data } = await ApiClient.patch(UserApiUrls.update(id).replace(/\|/i, '%7C'), body, { headers: getAuthHeaders() });
      setLoading(false);
      messageApi?.success(t('update_user_success'));
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error: any) {
      setLoading(false);
      if (error.response.status === 304) {
        messageApi?.error(t('please_select_at_least_one_role'));
      } else {
        messageApi?.error(error.response.data.message);
      }
      if (onError) onError(error);
      return null;
    }
  };
  return { loading, performAction: performUpdateUser };
};
