import { Button, Checkbox, CheckboxGroup, CreateEditLayout, AiFillExclamationCircle, confirm, useTranslation } from '@iib/pandora-box';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { NotificationTemplate } from '../../../types';
import { templateTypeLabels } from '../../../utils';
import { useCreateTemplate, useGetTemplate, useUpdateTemplate } from '../hooks';
import { useDeleteTemplate } from '../hooks/delete-notification-template';

import * as S from './styles';
import { EmailDesignModal } from '../../../components';
import { Design, HtmlExport } from 'react-email-editor';

export const NotificationCreate: FC = (): JSX.Element => {
  const {
    location: { pathname },
    push,
  } = useHistory();
  const id = Number(pathname.split('/')[3]) || null;
  const [template, setTemplate] = useState<NotificationTemplate>();
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingTimer, setLoadingTimer] = useState<NodeJS.Timeout>();
  const { performAction: getTemplate, loading: loadingTemplate } = useGetTemplate();
  const { performAction: UpdateTemplate } = useUpdateTemplate();
  const { performAction: CreateTemplate } = useCreateTemplate();
  const { performAction: deleteTemplate } = useDeleteTemplate();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [design, setDesign] = useState<Design>();
  const [content, setContent] = useState<string>();
  const { t } = useTranslation();

  const fetchTemplate = async () => {
    if (!id) return;
    const fetchedTemplate = await getTemplate({ id });
    if (!fetchedTemplate) return;
    setTemplate(fetchedTemplate);
  };

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedTypes(checkedValues.map((value) => value.toString()));
  };

  const handleSubmit = async ({ identifier, subject, embedIntoIdentifier }: Partial<NotificationTemplate>) => {
    const body = {
      identifier,
      customerId: 0,
      design: design || template?.design,
      subject,
      embedIntoIdentifier,
      content: content || template?.content,
      types: selectedTypes,
    };

    if (id && template) {
      await UpdateTemplate({ id, body, onSuccess: () => push(`/notifications/templates/${id}/detail`) });
    } else {
      await CreateTemplate({ body, onSuccess: (created) => push(`/notifications/templates/${created.id}/detail`) });
    }
  };

  const showDeleteConfirm = (deleteId: number) => {
    confirm({
      title: t('delete_template_heading'),
      icon: <AiFillExclamationCircle />,
      okType: 'danger',
      cancelText: t('cancel'),
      async onOk() {
        const response = await deleteTemplate({ id: deleteId });
        if (!!response && !('statusCode' in response)) {
          push('/notifications/templates');
        }
      },
    });
  };

  const handleDesignOk = (html: HtmlExport) => {
    setDesign(html.design);
    setContent(encodeURIComponent(html.html));
    setModalOpen(false);
  };

  useEffect(() => {
    if (!id) setLoading(false);
    else {
      clearTimeout(loadingTimer);
      const timeout = setTimeout(() => setLoading(!!loadingTemplate), 50);
      setLoadingTimer(timeout);
    }
  }, [loadingTemplate]);

  useEffect(() => {
    if (!id) return;
    fetchTemplate();
  }, [id]);

  useEffect(() => {
    if (!template || loading) return;
    setSelectedTypes(template?.types);
  }, [template, loading]);

  return (
    <>
      <EmailDesignModal open={modalOpen} onClose={() => setModalOpen(false)} initialDesign={template?.design} onOk={handleDesignOk} />
      <CreateEditLayout
        width="95%"
        columns={1}
        loading={loading}
        Loader={<Loading />}
        header={{
          title: pathname === '/notifications/templates/create' ? t('new_template') : t('edit_template'),
          ...(id && { rightButton: { onClick: () => push(`/notifications/templates/${id}/detail`), text: t('cancel') }, showDelete: true, onDelete: () => showDeleteConfirm(id) }),
        }}
        record={template}
        handleSubmit={handleSubmit}
        buttonLabel={t('submit')}
        fields={{
          identifier: { label: t('template_name'), status: (name) => (!name ? 'warning' : '') },
          types: {
            label: t('type'),
            render: (record) => {
              const allTypes = Object.keys(templateTypeLabels);
              const types = record?.types || selectedTypes;

              return (
                <CheckboxGroup style={{ width: '100%' }} onChange={onChange} defaultValue={types}>
                  <S.CheckboxList>
                    {allTypes.map((type) => (
                      <div key={type}>
                        <Checkbox value={type}>{type in templateTypeLabels ? templateTypeLabels[type] : type}</Checkbox>
                      </div>
                    ))}
                  </S.CheckboxList>
                </CheckboxGroup>
              );
            },
          },
          subject: { label: t('subject'), status: (name) => (!name ? 'warning' : '') },
          embedIntoIdentifier: { label: t('embed_into_identifier') },
          design: {
            label: 'Design',
            render: () => <Button onClick={() => setModalOpen(true)}>{t('edit_design')}</Button>,
          },
        }}
      />
    </>
  );
};
