import { useContext, useState } from 'react';
import { ApiClient } from '../../../services/api';
import { UserApiUrls, getAuthHeaders } from '../../../utils';
import { User } from '../../../types';
import { useTranslation, PandoraContext } from '@iib/pandora-box';

type Params = {
  id: string;
};

type HookReturnType = {
  performAction: (params: Params) => Promise<User | null>;
  loading: boolean;
};

export const useGetUser = (): HookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { messageApi } = useContext(PandoraContext);

  const performGetUser = async (params: Params): Promise<User | null> => {
    try {
      setLoading(true);
      const { data } = await ApiClient.get(UserApiUrls.getById(params.id).replace(/\|/i, '%7C'), { headers: getAuthHeaders() });
      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
      messageApi?.error(t('api_error_generic'));
      return null;
    }
  };
  return { loading, performAction: performGetUser };
};
