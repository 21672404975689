import { DetailLayout, AiFillExclamationCircle, confirm, useTranslation } from '@iib/pandora-box';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Loading from '../../../components/Loading';
import { NotificationTemplate } from '../../../types';
import { templateTypeLabels } from '../../../utils';
import { useGetTemplate } from '../hooks';

import { useDeleteTemplate } from '../hooks/delete-notification-template';
import * as S from './styles';

export const NotificationDetail: FC = (): JSX.Element => {
  const {
    location: { pathname },
    push,
  } = useHistory();
  const id = pathname.split('/')[3];
  const [template, setTemplate] = useState<NotificationTemplate | null>(null);
  const { performAction: getTemplate, loading } = useGetTemplate();
  const { performAction: deleteTemplate } = useDeleteTemplate();
  const { t } = useTranslation();

  const fetchTemplate = async () => {
    const fetchedTemplate = await getTemplate({ id });
    setTemplate(fetchedTemplate);
  };

  const showDeleteConfirm = (deleteId: number) => {
    confirm({
      title: t('delete_template_heading'),
      icon: <AiFillExclamationCircle />,
      okType: 'danger',
      cancelText: t('cancel'),
      async onOk() {
        const response = await deleteTemplate({ id: deleteId });
        if (!!response && !('statusCode' in response)) {
          push('/notifications/templates');
        }
      },
    });
  };

  useEffect(() => {
    fetchTemplate();
  }, [id]);

  return (
    <>
      {template && (
        <DetailLayout
          width="100%"
          columns={1}
          loading={loading}
          Loader={<Loading />}
          header={{
            title: t('template_data'),
            rightButton: { onClick: () => push(`/notifications/templates/${id}/edit`), text: t('edit_template') },
            showDelete: true,
            onDelete: () => showDeleteConfirm(id),
          }}
          record={template}
          fields={{
            identifier: { label: t('template_name') },
            types: {
              label: t('type'),
              render: (types) => {
                const allTypes = Object.keys(templateTypeLabels);

                return (
                  <>
                    {allTypes.map((type) => (
                      <S.CheckboxList key={type}>
                        <S.TypeCheckbox checked={!!types.find((key) => key === type)}>{type in templateTypeLabels ? templateTypeLabels[type] : type}</S.TypeCheckbox>
                      </S.CheckboxList>
                    ))}
                  </>
                );
              },
            },
            subject: { label: t('subject') },
            embedIntoIdentifier: { label: t('embed_into_identifier') },
          }}
        />
      )}
    </>
  );
};
