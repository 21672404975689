import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NewCorrectionsDetail } from './new-correction-detail';
import { NewCorrectionsList } from './new-corrections-list';
import * as S from './styles';
import { MapProvider } from '@iib/pandora-box';

export default function Component(): JSX.Element {
  return (
    <S.Wrapper>
      <MapProvider>
        <Switch>
          <Route path="/residentialArea/newCorrections" exact component={NewCorrectionsList} />
          <Route path="/residentialArea/newCorrections/detail/:id" component={NewCorrectionsDetail} />
        </Switch>
      </MapProvider>
    </S.Wrapper>
  );
}
