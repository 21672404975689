import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '@iib/pandora-box';
import React, { FunctionComponent, ReactElement } from 'react';
import auth from '../../services/auth';

import * as S from './styles';

interface NavigationProps {
  label?: string;
  children: any;
}

export const Navigation = ({ label, children }: NavigationProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      {t(label) && (
        <>
          <S.NavHeading>{t(label)}</S.NavHeading>
        </>
      )}
      {children}
    </>
  );
};

interface NavigationItemProps {
  label: string;
  icon: IconProp;
  path?: string;
  permissions?: string[];
  index?: number;
}

export const NavigationItem: FunctionComponent<NavigationItemProps> = ({ permissions, path, label, icon }) => {
  const { t } = useTranslation();

  // Check permissions
  if (permissions && !auth.hasPermission(permissions)) return <span />;

  return (
    <S.NavList>
      <S.NavLink to={path}>
        <FontAwesomeIcon width="25px" icon={icon} />
        {t(label)}
      </S.NavLink>
    </S.NavList>
  );
};
