import React, { FC, useRef, useState } from 'react';
import { Design, EditorRef, HtmlExport } from 'react-email-editor';
import { MaybePromise } from '../../types';
import { TestID } from '../../utils';
import { useTranslation } from '@iib/pandora-box';

import * as S from './styled';

export type EmailDesignModalProps = {
  initialDesign?: Design;
  open?: boolean;
  onOk?: (html: HtmlExport) => MaybePromise<void>;
  onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => MaybePromise<void>;
};

export const EmailDesignModal: FC<EmailDesignModalProps> = ({ initialDesign, open, onOk, onClose }) => {
  const emailEditorRef = useRef<EditorRef>(null);
  const [html, setHtml] = useState<HtmlExport>();
  const { t } = useTranslation();

  const onReady = (): void => {
    const emailDesign = html?.design || initialDesign;

    if (emailDesign) emailEditorRef?.current?.editor?.loadDesign(emailDesign);
    else emailEditorRef?.current?.editor?.loadBlank({});
  };

  const getContent = async (): Promise<HtmlExport> => {
    return new Promise<HtmlExport>((resolve) => {
      emailEditorRef?.current?.editor?.exportHtml((htmlExport: HtmlExport) => {
        setHtml(htmlExport);
        resolve(htmlExport);
      });
    });
  };

  const handleOk = async () => {
    const html = await getContent();
    if (onOk) onOk(html);
  };

  return (
    <S.Modal data-testid={TestID.EmailDesignModal} prefixCls="email-design" cancelText={t('cancel')} closable={false} destroyOnClose open={open} onOk={handleOk} onCancel={onClose}>
      <S.EmailEditorWrapper>
        <S.EmailEditor ref={emailEditorRef} editorId="admin-ui-eamil-editor" options={{ displayMode: 'web' }} minHeight={'750px'} onReady={onReady} />
      </S.EmailEditorWrapper>
    </S.Modal>
  );
};
