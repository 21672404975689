import { useContext, useState } from 'react';
import { ApiClient } from '../../../services/api';
import { RatingsApiUrls, getAuthHeaders } from '../../../utils';
import { Rating, StatusError } from '../../../types';
import { isAxiosError } from 'axios';
import { useTranslation, PandoraContext } from '@iib/pandora-box';

type Params = {
  id: string;
};

type HookReturnType = {
  performAction: (params: Params) => Promise<(Rating & { position: any }) | null | StatusError>;
  loading: boolean;
};

export const useDeleteRating = (): HookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { messageApi } = useContext(PandoraContext);

  const performDeleteRating = async (params: Params): Promise<(Rating & { position: any }) | null | StatusError> => {
    try {
      setLoading(true);
      const { data } = await ApiClient.delete(RatingsApiUrls.deleteById(params.id), { headers: getAuthHeaders() });
      setLoading(false);
      messageApi?.success(t('rating_deleted'));
      return data;
    } catch (error) {
      setLoading(false);
      messageApi?.error(t('api_error_generic'));
      if (isAxiosError(error)) {
        return error?.response?.data;
      } else {
        return null;
      }
    }
  };
  return { loading, performAction: performDeleteRating };
};
