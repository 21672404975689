import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';

export default function (): ReactElement {
  return (
    <div
      style={{
        color: '#222',
        display: 'flex',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 45,
      }}
    >
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  );
}
