import React, { ReactElement, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'jquery';
import 'bootstrap';
import '@iib/pandora-box/dist/mtk.css';
// AdminLTE components need to be imported individually.
// There's a bug in SidebarSearch.js that crashes the app.

import './icons';
import './App.scss';
import Auth from './services/auth';
import AppLoading from './components/AppLoading';
import Module from './components/Module';
import modules from './modules';
import Home from './features/Home';
import { useAuth0 } from '@auth0/auth0-react';

const App = (): ReactElement => {
  const { isAuthenticated, loginWithRedirect, user, isLoading: loading, getAccessTokenSilently } = useAuth0();
  const [appLoading, setAppLoading] = useState(true);

  if (loading) {
    return <AppLoading />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: location.pathname,
      },
    });
    return <div />;
  }

  (async (): Promise<void> => {
    Auth.setAccessToken(await getAccessTokenSilently());
    Auth.setUser(user);
    setAppLoading(false);
  })();

  if (appLoading) {
    return <AppLoading />;
  }

  return (
    <>
      <Router>
        {modules.map((config) => (
          <Route key={config.name} path={`/${config.name}`}>
            <Module config={config} />
          </Route>
        ))}
        <Route path="/" exact>
          <Home />
        </Route>
      </Router>
    </>
  );
};

export default App;
