import { useContext, useState } from 'react';
import { ApiClient } from '../../../services/api';
import { NotificationApiUrls, getAuthHeaders } from '../../../utils';
import { StatusError } from '../../../types';
import { isAxiosError } from 'axios';
import { useTranslation, PandoraContext } from '@iib/pandora-box';

type DeleteResponse = {
  raw: any[];
  affected: number;
};

type Params = {
  id: number;
};

type HookReturnType = {
  performAction: (params: Params) => Promise<DeleteResponse | null | StatusError>;
  loading: boolean;
};

export const useDeleteTemplate = (): HookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { messageApi } = useContext(PandoraContext);

  const performDeleteTemplate = async (params: Params): Promise<DeleteResponse | null | StatusError> => {
    try {
      setLoading(true);
      const { data } = await ApiClient.delete(NotificationApiUrls.deleteById(params.id), { headers: getAuthHeaders() });
      setLoading(false);
      messageApi?.success(t('delete_template_success'));
      return data;
    } catch (error) {
      setLoading(false);
      messageApi?.error(t('api_error_generic'));
      if (isAxiosError(error)) {
        return error?.response?.data;
      } else {
        return null;
      }
    }
  };
  return { loading, performAction: performDeleteTemplate };
};
