import { PALETTE, Checkbox as PandoraCheckbox, Search as PandoraSearch } from '@iib/pandora-box';
import { Card } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  @media screen and (max-width: 767px) {
    padding: 0px;
  }
`;

export const RoleCheckbox = styled(PandoraCheckbox)`
  color: #505050;
  font-weight: 500;
  pointer-events: none;
`;

export const DetailWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  padding-top: 20px;
  margin-top: 20px;
`;

export const DetailHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  width: 100%;
`;

export const ModalHeader = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  margin-bottom: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
`;

export const Heading = styled.h4`
  width: 100%;
  text-align: center;
`;

export const ToggleSelected = styled(PandoraCheckbox)`
  & > span {
    margin: auto;
  }
`;

export const CustomerModal = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: row;
  gap: 5px;
`;

export const ModalSearch = styled(PandoraSearch)`
  margin-bottom: 5px;
`;

export const LightWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

export const Light = styled.span<{ color: string }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${(props) => props.color};
`;

export const Spacer = styled.div<{ width: string | number }>`
  width: ${(props) => props.width};
`;

export const ListItem = styled.div`
  padding: 12px 24px;
  text-align: start;
`;

export const FieldItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 150px;
`;

export const ViewWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const RoleList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: start;

  @media screen and (max-width: 575px) {
    & > div {
      margin-bottom: 3px;
      width: 100%;
      border-bottom: 1px solid ${PALETTE.GRAY[200]};
    }
  }
`;

export const CustomerButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
