import { Checkbox as PandoraCheckbox, Search } from '@iib/pandora-box';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  margin: auto;

  @media screen and (max-width: 767px) {
    padding: 0px;
  }
`;

export const TypeCheckbox = styled(PandoraCheckbox)`
  color: #505050;
  font-weight: 500;
  pointer-events: none;
`;

export const ModalSearch = styled(Search)`
  margin-bottom: 20px;
`;

export const ViewWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const EmailEditorWrapper = styled.div`
  width: 100%;
  min-width: 300px;
  max-width: inherit;
  height: fit-content;
  overflow-x: auto;
  container-type: inline-size; // fixes width overflow issue of EmailEditor iframe
`;

export const CheckboxList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
