import { Button } from 'antd';
import styled from 'styled-components';

export const NoPermission = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 20px 5px 20px 30px;
  font-size: 21px;
  line-height: 26px;
  color: '#707070';
`;

export const ContactSupportLink = styled(Button)`
  margin: auto;
  margin-top: 20px;
  height: 40px;
  max-width: 400px;
  font-size: 18px;
  line-height: 26px;
`;
