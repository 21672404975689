import { faEnvelopeOpenText, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Navigation, NavigationItem } from '../../components/navigation';
import ModuleInterface from '../../types/general';
import Notifications from './features/Notifications';

const config: ModuleInterface = {
  name: 'notifications',
  title: 'notification_templates',
  permissions: ['notifications:admin'],
  root: '/notifications/templates',
  startPage: '/notifications/templates',
  navigation: [
    <Navigation label="notification_templates" key="navigation">
      <NavigationItem label="template_list" icon={faEnvelopeOpenText} path="/notifications/templates" />
      <NavigationItem label="create_template" icon={faMailBulk} path="/notifications/templates/create" />
    </Navigation>,
  ],

  routes: [
    {
      path: '/',
      component: Notifications,
    },
  ],
};
export default config;
