import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotificationList } from './list';
import { NotificationDetail } from './detail';
import * as S from './styles';
import { NotificationCreate } from './create';

export default function Component(): JSX.Element {
  return (
    <S.Wrapper>
      <Switch>
        <Route path="/notifications/templates" exact component={NotificationList} />
        <Route path="/notifications/templates/:id/detail" exact component={NotificationDetail} />
        <Route path="/notifications/templates/:id/edit" exact component={NotificationCreate} />
        <Route path="/notifications/templates/create" component={NotificationCreate} />
      </Switch>
    </S.Wrapper>
  );
}
