import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import { PandoraProvider } from '@iib/pandora-box';
import App from './App';
import * as serviceWorker from './serviceWorker';
import history from './utils/history';
import { getEnv } from './utils/getEnv';
import { Auth0Provider } from '@auth0/auth0-react';
import { AppState } from '@auth0/auth0-react/src/auth0-provider';

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: AppState): void => {
  history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const container = document.getElementById('root');
if (!container) {
  throw new Error('No container found');
}

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={getEnv('REACT_APP_AUTH_DOMAIN') as string}
      clientId={getEnv('REACT_APP_AUTH_CLIENT_ID') as string}
      authorizationParams={{
        redirect_uri: typeof window !== 'undefined' && window.location.origin ? window.location.origin : '',
        audience: getEnv('REACT_APP_AUTH_AUDIENCE'),
      }}
      onRedirectCallback={onRedirectCallback as any}
    >
      <PandoraProvider>
        <App />
      </PandoraProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
