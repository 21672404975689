import { faUser } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Navigation, NavigationItem } from '../../components/navigation';
import ModuleInterface from '../../types/general';
import Users from './features/Users';

export const config: ModuleInterface = {
  name: 'users',
  title: 'user_c',
  permissions: ['users:admin'],
  startPage: '/users',
  navigation: [
    <Navigation label="user_c" key="navigation">
      <NavigationItem label="user_list" icon={faUser} path="/users" />
      <NavigationItem label="create_user" icon={faUser} path="/users/create" />
    </Navigation>,
  ],
  routes: [
    {
      path: '/',
      component: Users,
    },
  ],
};
export default config;
