import { useContext, useState } from 'react';
import { ApiClient } from '../../../services/api';
import { NotificationApiUrls, getAuthHeaders } from '../../../utils';
import { MaybePromise, NotificationTemplate } from '../../../types';
import { Design } from 'react-email-editor';
import { useTranslation, PandoraContext } from '@iib/pandora-box';

type Body = {
  types?: string[];
  customerId?: number;
  identifier?: string;
  content?: string;
  design?: Design;
  subject?: string;
  embedIntoIdentifier?: any;
};

type Props = {
  body: Body;
  id: number;
  onSuccess?: (updated: NotificationTemplate) => MaybePromise<void>;
  onError?: (error: any) => MaybePromise<void>;
};
type HookReturnType = {
  performAction: (props: Props) => Promise<NotificationTemplate | null>;
  loading: boolean;
};

export const useUpdateTemplate = (): HookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { messageApi } = useContext(PandoraContext);

  const performUpdateTemplate = async ({ id, body, onSuccess, onError }: Props): Promise<NotificationTemplate | null> => {
    try {
      setLoading(true);
      const { data } = await ApiClient.patch(NotificationApiUrls.update(id), body, { headers: getAuthHeaders() });
      setLoading(false);
      messageApi?.success(t('update_template_success'));
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      setLoading(false);
      messageApi?.error(t('api_error_generic'));
      if (onError) onError(error);
      return null;
    }
  };
  return { loading, performAction: performUpdateTemplate };
};
