import { useContext, useState } from 'react';
import { ApiClient } from '../../../services/api';
import { RatingsApiUrls, getAuthHeaders } from '../../../utils';
import { Rating, PaginatedList } from '../../../types';
import { useTranslation, PandoraContext } from '@iib/pandora-box';

type Params = {
  page?: number;
  itemsPerPage?: number;
  sort?: Record<string, 'ASC' | 'DESC'>;
  filter?: Record<string, any>;
  query?: string;
};

type HookReturnType = {
  performAction: (params: Params) => Promise<PaginatedList<Rating>>;
  loading: boolean;
};

export const useListRatings = (): HookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { messageApi } = useContext(PandoraContext);

  const performListRatings = async (params: Params): Promise<PaginatedList<Rating>> => {
    try {
      setLoading(true);
      const { data } = await ApiClient.get(RatingsApiUrls.list, { headers: getAuthHeaders(), params });
      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
      messageApi?.error(t('api_error_generic'));
      return { page: 1, items: [], numberOfItems: 0, numberOfPages: 0 };
    }
  };
  return { loading, performAction: performListRatings };
};
