import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  @media screen and (max-width: 767px) {
    padding: 0px;
  }
`;

export const MapWrapper = styled.div`
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  min-width: 200px;
  width: 100%;
  max-width: inherit;
  height: 500px;
  container-type: inline-size; // fixes width overflow issue of Map
`;

export const Light = styled.span<{ color: string }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${(props) => props.color};
`;

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
`;
