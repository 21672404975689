import { useContext, useState } from 'react';
import { ApiClient } from '../../../services/api';
import { UserApiUrls, getAuthHeaders } from '../../../utils';
import { Role } from '../../../types';
import { useTranslation, PandoraContext } from '@iib/pandora-box';

type HookReturnType = {
  performAction: () => Promise<Array<Role>>;
  loading: boolean;
};

export const useListUserRoles = (): HookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { messageApi } = useContext(PandoraContext);

  const performListUserRoles = async (): Promise<Array<Role>> => {
    try {
      setLoading(true);
      const { data } = await ApiClient.get(UserApiUrls.roles, { headers: getAuthHeaders() });
      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
      messageApi?.error(t('api_error_generic'));
      return [];
    }
  };
  return { loading, performAction: performListUserRoles };
};
