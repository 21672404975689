import { ShouldRender, useTranslation } from '@iib/pandora-box';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import modules from '../modules';
import auth from '../services/auth';

import * as S from './styles';

export default (): ReactElement<any, any> | null => {
  const { t } = useTranslation();
  const filteredModules = modules.filter((module) => auth.hasPermission(module.permissions));

  return (
    <Layout>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex flex-row align-items-between pt-3">
              <ShouldRender if={filteredModules?.length}>
                {filteredModules.map((module) => (
                  <div className="card mx-2" style={{ width: '18rem', height: '10rem' }} key={module.name}>
                    <div className="card-body p-0">
                      <Link to={module.startPage ? module.startPage : `/${module.name}`} className="h-100 w-100 d-flex align-items-center justify-content-center">
                        {t(module.title)}
                      </Link>
                    </div>
                  </div>
                ))}
              </ShouldRender>
              <ShouldRender if={!filteredModules.length}>
                <S.NoPermission>
                  {t('no_permission_text')}{' '}
                  <S.ContactSupportLink type="primary" href="mailto: service@iib-institut.de" target="__blank">
                    {t('contact_support')}
                  </S.ContactSupportLink>
                </S.NoPermission>
              </ShouldRender>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
