import { MailTwoTone } from '@ant-design/icons';
import type { ColumnsType } from '@iib/pandora-box';
import { Badge, Button, Checkbox, CheckboxGroup, CreateEditLayout, Modal, Table, TextArea, useTranslation } from '@iib/pandora-box';
import { List, Tooltip } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { TableRowSelection } from 'antd/es/table/interface';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import type { CustomerWithKey, PaginatedList, SelectionType } from '../../../types';
import { Customer, Role, User } from '../../../types';
import { mapSelectionToCustomers, TestID } from '../../../utils';
import { useCreateUser, useGetAllCustomers, useGetCustomer, useGetUser, useListUserRoles, useUpdateUser } from '../hooks';
import * as S from './styles';
import { UserActions } from '../../../components/common';

const columns: ColumnsType<CustomerWithKey> = [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'left',
    sorter: (a, b) => Number(a.id) - Number(b.id),
  },
  {
    title: 'Name',
    dataIndex: 'companyName',
    align: 'center',
    sorter: (a, b) => (a.companyName?.trim()?.toLowerCase() > b.companyName?.trim()?.toLowerCase() ? 1 : -1),
  },
  {
    title: 'Ort',
    dataIndex: 'city',
    render: (value) => <div style={{ textAlign: 'center', maxHeight: '100%' }}>{value ? <p>{value}</p> : <Badge status={'error'} />}</div>,
    align: 'center',
    sorter: (a, b) => {
      if (!b.city) return -1;
      if (!a.city) return 1;

      return a.city?.trim()?.toLowerCase() > b.city?.trim()?.toLowerCase() ? 1 : -1;
    },
  },
  {
    title: 'PLZ',
    dataIndex: 'zipcode',
    render: (value) => <div style={{ textAlign: 'center' }}>{value ? <p>{value}</p> : <Badge status={'error'} />}</div>,
    align: 'center',
    sorter: (a, b) => {
      if (!b.zipcode) return -1;
      if (!a.zipcode) return 1;

      return a.zipcode?.trim()?.toLowerCase() > b.zipcode?.trim()?.toLowerCase() ? 1 : -1;
    },
  },
];

export const UserCreate: FC = () => {
  const {
    location: { pathname },
    push,
  } = useHistory();

  const id = pathname.split('/')[3];

  const [initialLoader, setInitialLoader] = useState<boolean>(true);

  const [showModal, setShowModal] = useState(false);
  const [customerLoading, setCustomerLoading] = useState<boolean>(true);
  const [customerModalData, setCustomerModalData] = useState<PaginatedList<CustomerWithKey>>({ page: 1, numberOfItems: 0, numberOfPages: 0, items: [] });
  const [allCustomersData, setAllCustomersData] = useState<CustomerWithKey[]>([]);
  const [finalSelectedRows, setFinalSelectedRows] = useState<CustomerWithKey[]>();
  const [query, setQuery] = useState<string>();
  const [selectionType, setSelectionType] = useState<SelectionType>('multi');

  const [roles, setRoles] = useState<Array<Role>>();
  const [user, setUser] = useState<User>();
  const [finalSelectedCompany, setFinalSelectedCompany] = useState<Customer>();
  const [selectedRoles, setSelectedRoles] = useState<string[]>();
  const [selection, setSelection] = useState<React.Key[]>([]);
  const [selectedToggle, setSelectedToggle] = useState<boolean>(false);

  const { performAction: getRoles } = useListUserRoles();
  const { performAction: getAllCustomers } = useGetAllCustomers();
  const { performAction: getCustomer } = useGetCustomer();
  const { performAction: getUser } = useGetUser();
  const { performAction: createUser } = useCreateUser();
  const { performAction: updateUser } = useUpdateUser();
  const { t } = useTranslation();

  const fetchData = async () => {
    if (id) {
      const fetchedUser = await getUser({ id });
      if (fetchedUser) setUser(fetchedUser);
    }

    const result = await getRoles();
    setRoles(result);
  };

  const fetchCustomers = async (searchQuery?: string) => {
    setCustomerLoading(true);

    const fetchedCustomers: PaginatedList<CustomerWithKey> = await getAllCustomers({ query: searchQuery });
    fetchedCustomers.items = fetchedCustomers.items
      .filter((item) => item.active !== false)
      .map((item) => {
        return { ...item, key: item.id };
      });
    fetchedCustomers.numberOfItems = fetchedCustomers.items.length;

    if (!allCustomersData?.length && !searchQuery) setAllCustomersData(fetchedCustomers.items);
    setCustomerModalData(fetchedCustomers);
    setCustomerLoading(false);
  };

  const onCustomerSelect = (customer: CustomerWithKey, isSelect: boolean, _selected: CustomerWithKey[]) => {
    const exists = allCustomersData.find((c) => c.id === customer.id);
    if (!exists) {
      // if selected customer is not in all customers list, then we add it
      setAllCustomersData((prev) => [...prev, customer]);
    }
    if (selectionType === 'single' && isSelect) {
      setSelection([customer.id]);
      return;
    }

    if (!isSelect) {
      setSelection((current) => current?.filter((c) => c !== customer.id));
      return;
    }

    setSelection((current) => [...current, customer?.id]);
  };

  const rowSelection: TableRowSelection<CustomerWithKey> = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selection,
    onSelect: onCustomerSelect,
    type: 'checkbox',
  };

  const handleOk = () => {
    setShowModal(false);
    const filteredData = mapSelectionToCustomers(selection, allCustomersData, customerModalData.items);

    if (selectionType === 'multi') {
      setFinalSelectedRows(filteredData);
    } else {
      setFinalSelectedCompany(filteredData[0]);
    }
    setSelection([]);
    setSelectedToggle(false);
  };

  const handleCancel = () => {
    setSelection([]);
    setShowModal(false);
    setSelectedToggle(false);
  };

  const onSearch = (value: any) => {
    fetchCustomers(value);
  };

  const onRolesChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedRoles(checkedValues.map((value) => value.toString()));
  };

  const handleSubmit = async ({ givenName, familyName, email, phoneNumber, notes }: Partial<User>) => {
    const body = {
      givenName,
      familyName,
      email,
      phoneNumber,
      notes,
      roles: selectedRoles,
      customer: Number(finalSelectedCompany?.id),
      customers: finalSelectedRows?.map((row) => Number(row.id)),
    };
    if (id && user) {
      await updateUser({ id, body, onSuccess: () => push(`/users/detail/${id}`) });
    } else {
      await createUser({ body, onSuccess: (created) => push(`/users/detail/${created.id.replace('auth0|', '')}`) });
    }
  };

  useEffect(() => {
    fetchCustomers().then(() => fetchData().then(() => setInitialLoader(false)));
  }, []);

  const setUserSelectedCompany = async (user: User) => {
    if (!user.customer) return;

    const exists = [user.customer].map((c) => allCustomersData.filter((item) => Number(item.id) === c)).flat(1)?.[0];
    if (exists) {
      setFinalSelectedCompany(exists);
      return;
    }

    const fetchedCustomer = await getCustomer({ id: user.customer });
    if (!fetchedCustomer) return;

    // if the fetched customer is not in all customers list, then we add it
    setAllCustomersData((prev) => [...prev, fetchedCustomer]);
    setFinalSelectedCompany(fetchedCustomer);
  };

  const setUserSelectedCompanies = async (user: User) => {
    if (!user.customers?.length) return;
    const existing = user.customers.map((c) => allCustomersData.filter((item) => Number(item.id) === c)).flat(1);
    if (existing.length === user.customers.length) {
      setFinalSelectedRows(existing);
      return;
    }

    // here we handle customers that are in user's customer list, but not in our all customers list
    const missing = user.customers.filter((customerId) => !existing.find((customer) => customer.id === String(customerId)));
    const missingCustomersPromises = missing.map((customerId) => getCustomer({ id: customerId }));
    const fetchedCustomers = missingCustomersPromises ? await Promise.all(missingCustomersPromises) : [];
    const missingCustomers = fetchedCustomers.filter((customer) => !!customer) as Customer[];

    setFinalSelectedRows(existing.concat(missingCustomers));
    setAllCustomersData((prev) => [...prev, ...missingCustomers]);
  };

  useEffect(() => {
    if (!user || customerLoading || finalSelectedRows?.length) return;
    setSelectedRoles(user?.roles?.map((role) => role.name));
    setUserSelectedCompany(user).then(() => {
      setUserSelectedCompanies(user);
    });
  }, [user, customerLoading]);

  return (
    <S.ViewWrapper data-testid={TestID.UserCreate}>
      <Modal
        title={
          <S.ModalHeader>
            <S.Heading>{selectionType === 'multi' ? t('select_customers') : t('select_customer')}</S.Heading>
            <S.ModalSearch value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Search table" onSearch={onSearch} />
          </S.ModalHeader>
        }
        open={showModal}
        onCancel={handleCancel}
        onOk={handleOk}
        width="800px"
        closable={false}
        destroyOnClose
      >
        <Table
          rowSelection={{
            ...rowSelection,
            columnTitle: (
              <Tooltip placement="topLeft" title={t('toggle_selected')}>
                <S.ToggleSelected checked={selectedToggle} onChange={() => setSelectedToggle(!selectedToggle)} />
              </Tooltip>
            ),
          }}
          columns={columns}
          dataSource={selectedToggle ? customerModalData.items.filter((item) => selection?.includes(item.id)) : customerModalData.items}
          loading={customerLoading}
          pagination={{
            total: selectedToggle ? customerModalData.items.filter((item) => selection?.includes(item.id)).length : customerModalData.numberOfItems,
            showSizeChanger: false,
            pageSize: 10,
          }}
        />
      </Modal>
      <CreateEditLayout
        width="100%"
        columns={2}
        loading={initialLoader}
        Loader={<Loading />}
        header={{
          title: pathname === '/users/create' ? t('new_user') : t('edit_user'),
          rightButton: user ? <UserActions id={id} blocked={user?.blocked} onOk={() => push(`/users/detail/${id}`)} isEdit /> : <></>,
        }}
        record={user}
        handleSubmit={handleSubmit}
        buttonLabel={t('submit')}
        fields={{
          givenName: {
            label: t('given_name'),
            span: 2,
            status: (name) => (!name ? 'warning' : ''),
          },
          familyName: {
            label: t('family_name'),
            span: 2,
            status: (name) => (!name ? 'warning' : ''),
          },
          email: { label: t('email_c'), status: (email) => (email.indexOf('@') === -1 ? 'error' : ''), span: 2, suffix: <MailTwoTone /> },
          phoneNumber: { label: t('phone_number'), span: 2, status: (number) => (!number ? 'warning' : '') },
          customers: {
            label: t('can_access_customers'),
            span: 2,
            render: () => {
              return (
                <>
                  {!!finalSelectedRows?.length && (
                    <List
                      dataSource={finalSelectedRows}
                      style={{
                        marginTop: '15px',
                        marginBottom: '5px',
                        marginRight: '10px',
                      }}
                      renderItem={(item) => (
                        <Tooltip placement="left" title={`ID: ${item.id}`}>
                          <S.ListItem key={item.id}>
                            {item.companyName} {item.city}
                          </S.ListItem>
                        </Tooltip>
                      )}
                    />
                  )}
                  <S.CustomerButtonWrapper>
                    <Button
                      onClick={() => {
                        setSelectionType('multi');
                        setSelection(finalSelectedRows?.map((row) => row?.id) || []);
                        setShowModal(true);
                      }}
                      style={{
                        height: '40px',
                        marginTop: '15px',
                        marginBottom: '5px',
                        marginRight: '10px',
                      }}
                    >
                      {t('select_customers')}
                    </Button>
                  </S.CustomerButtonWrapper>
                </>
              );
            },
          },
          customer: {
            label: t('belongs_to_customer'),
            span: 2,
            render: () => (
              <>
                {finalSelectedCompany !== undefined && (
                  <List
                    dataSource={[finalSelectedCompany]}
                    renderItem={(item) => (
                      <Tooltip placement="left" title={`ID: ${item.id}`}>
                        <S.ListItem key={item.id}>
                          {item.companyName} {item.city}
                        </S.ListItem>
                      </Tooltip>
                    )}
                  />
                )}
                <S.CustomerButtonWrapper>
                  <Button
                    onClick={() => {
                      setSelectionType('single');
                      setSelection(finalSelectedCompany ? [finalSelectedCompany.id] : []);
                      setShowModal(true);
                    }}
                    style={{
                      height: '40px',
                      marginTop: '15px',
                      marginBottom: '5px',
                      marginRight: '10px',
                    }}
                  >
                    {t('select_customer')}
                  </Button>
                </S.CustomerButtonWrapper>
              </>
            ),
          },
          roles: {
            label: t('roles_c'),
            span: 2,
            render: () => (
              <CheckboxGroup style={{ width: '100%' }} onChange={onRolesChange} defaultValue={selectedRoles}>
                <S.RoleList>
                  {roles?.map((element) => (
                    <div key={element.id}>
                      <Checkbox value={element.name}>{element?.description}</Checkbox>
                    </div>
                  ))}
                </S.RoleList>
              </CheckboxGroup>
            ),
          },
          notes: {
            label: t('register_tools'),
            span: 2,
            render: (fields, setFields) => (
              <TextArea
                value={fields?.notes}
                onChange={(e) => {
                  const notes = e.target.value;
                  setFields((current) => ({ ...current, notes }));
                }}
              />
            ),
          },
        }}
      />
    </S.ViewWrapper>
  );
};

export default UserCreate;
