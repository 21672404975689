export type Role = {
  id: string;
  name: string;
  description: string;
};

export type User = {
  id: string;
  familyName: string;
  email: string;
  givenName: string;
  name: string;
  phoneNumber: string;
  customer: number;
  customers: Array<number>;
  roles: Array<Role>;
  notes: string;
  affiliate: string;
  blocked: boolean;
};

export enum WithRolesOption {
  NONE = 'NONE',
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
  ALL = 'ALL',
}
