import { useContext, useState } from 'react';
import { ApiClient } from '../../../services/api';
import { CorrectionsApiUrls, getAuthHeaders } from '../../../utils';
import { Correction, StatusError } from '../../../types';
import { isAxiosError } from 'axios';
import { useTranslation, PandoraContext } from '@iib/pandora-box';

type Params = {
  id: string;
};

type HookReturnType = {
  performAction: (params: Params) => Promise<(Correction & { position: any }) | null | StatusError>;
  loading: boolean;
};

export const useDeleteCorrection = (): HookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { messageApi } = useContext(PandoraContext);

  const performDeleteCorrection = async (params: Params): Promise<(Correction & { position: any }) | null | StatusError> => {
    try {
      setLoading(true);
      const { data } = await ApiClient.delete(CorrectionsApiUrls.deleteById(params.id), { headers: getAuthHeaders() });
      setLoading(false);
      messageApi?.success(t('correction_deleted'));
      return data;
    } catch (error) {
      setLoading(false);
      messageApi?.error(t('api_error_generic'));
      if (isAxiosError(error)) {
        return error?.response?.data;
      } else {
        return null;
      }
    }
  };
  return { loading, performAction: performDeleteCorrection };
};
