import auth from '../services/auth';
import { SortOrder, type CustomerWithKey } from '../types';

export const ucFirst = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getAuthHeaders = (): Record<string, string> => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    Authorization: `Bearer ${auth.getAccessToken()}`,
  };
  return headers;
};

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
  return value !== null && value !== undefined;
};

export const convertSortOrder = (order: SortOrder): 'ASC' | 'DESC' => {
  return order === 'ascend' ? 'ASC' : 'DESC';
};

export const correctionStatusLabel = (status: string): string => {
  const correctionStatusMap: Record<string, string> = {
    geprüft: 'erledigt',
  };

  return correctionStatusMap[status] || status || 'N/A';
};

export const mapSelectionToCustomers = (selection: React.Key[], customersData: CustomerWithKey[], modalCustomers: CustomerWithKey[]): CustomerWithKey[] => {
  const filtered = selection
    .map((element) => {
      return customersData.concat(modalCustomers).find((item) => item.id === element.toString());
    })
    .filter((item) => !!item)
    .flat(1) as CustomerWithKey[];

  return filtered;
};
