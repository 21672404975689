import React, { ReactElement, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { RatingsList } from './new-ratings-list';
import { RatingDetail } from './rating-detail';
import * as S from './styles';
import { MapProvider } from '@iib/pandora-box';

export default (): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(false);
    })();
  }, []);

  if (loading) return <Loading />;

  return (
    <S.Wrapper>
      <MapProvider>
        <Switch>
          <Route path="/residentialArea/ratings" exact component={RatingsList} />
          <Route path="/residentialArea/ratings/detail/:id" component={RatingDetail} />
        </Switch>
      </MapProvider>
    </S.Wrapper>
  );
};
