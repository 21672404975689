import React, { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ModuleInterface from '../types/general';
import Layout from './Layout';

interface Props {
  config: ModuleInterface;
}

export default function Module({ config }: Props): ReactElement {
  const { path } = useRouteMatch();

  return (
    <Layout moduleConfig={config}>
      <Switch>
        {config.navigation
          .filter((cfg) => cfg.component)
          .map((cfg) => (
            <Route key={cfg.name} path={cfg.title}>
              {cfg.component}
            </Route>
          ))}
        {config.routes.map((cfg) => (
          <Route key={cfg.path} path={`${path}${cfg.path}`} exact={cfg.exact} component={cfg.component} />
        ))}
      </Switch>
    </Layout>
  );
}
