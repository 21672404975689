import { PALETTE, Modal as PandoraModal } from '@iib/pandora-box';
import { EmailEditor as ReactEmailEditor } from 'react-email-editor';
import styled from 'styled-components';

export const Modal = styled(PandoraModal)`
  top: 50% !important;
  height: fit-content;
  width: 100% !important;
  transform: translateY(-50%) !important;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1099px) {
    max-width: 100% !important;
    height: 100%;
    padding: 0px;

    & .email-design-body {
      height: calc(100% - 60px);
    }

    & .email-design-content {
      border-radius: 0px;
    }

    & .email-design-footer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .email-design-content {
    width: 100%;
    max-width: 1250px;
    margin: 0px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & .email-design-footer {
    margin: 0px;
    padding-top: 20px;
  }
`;

export const EmailEditor = styled(ReactEmailEditor)`
  border: 1px solid ${PALETTE.GRAY[200]};
`;

export const EmailEditorWrapper = styled.div`
  width: 100%;
  min-width: 300px;
  max-width: 100%;
  height: fit-content;
  min-height: 750px;
  overflow-x: auto;
  container-type: inline-size; // fixes width overflow issue of EmailEditor iframe
`;
