import type { ColumnsType } from '@iib/pandora-box';
import { ListLayout, useTranslation } from '@iib/pandora-box';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Correction } from '../../../types';
import { COLOR, convertSortOrder, correctionStatusLabel } from '../../../utils';
import { useListCorrections } from '../hooks';
import * as S from './styles';

const ITEMS_PER_PAGE = 15;

export const NewCorrectionsList: FC = () => {
  const history = useHistory();
  const [numberOfItems, setNumberOfItems] = useState<number>(0);
  const [items, setItems] = useState<Array<Correction & { key: string }>>([]);
  const [query, setQuery] = useState<string | undefined>();
  const [sort, setSort] = useState<Record<string, 'ASC' | 'DESC'>>();
  const { performAction, loading } = useListCorrections();
  const { t } = useTranslation();

  const fetchData = async (page = 1, searchQuery?: string, sorter?: Record<string, 'ASC' | 'DESC'>) => {
    const data = await performAction({ page, itemsPerPage: ITEMS_PER_PAGE, query: searchQuery, filter: { isResolved: false }, sort: sorter });
    setNumberOfItems(data.numberOfItems);
    setItems(data?.items.map((item) => ({ ...item, key: String(item.id) })));
  };

  const onSearch = (value: any) => {
    setQuery(value);
    fetchData(1, value, sort);
  };

  const handleTableChange = (page: number, sortData: any) => {
    const field = sortData?.field;
    const sorter = sortData?.order && sortData?.field ? { [field]: convertSortOrder(sortData?.order) } : undefined;
    setSort(sorter);
    fetchData(page, query, sorter);
  };

  const columns: ColumnsType<Correction> = [
    {
      title: t('created_at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '150px',
      sorter: true,
      render: (createdAt) => `${moment(createdAt).format('DD.MM.YYYY hh:mm')}${t('hour')}`,
    },
    {
      title: t('updated_at'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '150px',
      sorter: true,
      render: (updatedAt) => <>{`${moment(updatedAt).format('DD.MM.YYYY hh:mm')}${t('hour')}`}</>,
    },
    {
      title: t('checked_at'),
      dataIndex: 'checkedAt',
      key: 'checkedAt',
      width: '150px',
      sorter: false,
      render: (checkedAt) => <>{checkedAt ? `${moment(checkedAt).format('DD.MM.YYYY hh:mm')}${t('hour')}` : 'N/A'} </>,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      width: '150px',
      render: (status) => {
        let color = COLOR.YELLOW;

        if (status === 'gemeldet') color = COLOR.RED;
        else if (status === 'geprüft') color = COLOR.GREEN;

        return (
          <S.StatusWrapper>
            {correctionStatusLabel(status)}
            &nbsp;
            <S.Light color={color} />
          </S.StatusWrapper>
        );
      },
    },
    {
      title: t('user_c'),
      dataIndex: 'userEmail',
      key: 'userEmail',
      width: '250px',
      render: (userEmail) => {
        return <>{userEmail || 'N/A'}</>;
      },
    },
    {
      title: t('checked_by'),
      dataIndex: 'checkedBy',
      key: 'checkedBy',
      width: '150px',
      sorter: false,
      render: (checkedBy) => <>{checkedBy || 'N/A'}</>,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ListLayout
      width="100%"
      tableWidth="100%"
      searchPlaceHolder={t('search_table')}
      onSearch={onSearch}
      hideSearch
      columns={columns}
      dataSource={items}
      loading={loading}
      pageSize={ITEMS_PER_PAGE}
      total={numberOfItems}
      onRow={(item) => ({
        style: { cursor: 'pointer' },
        onClick: () => history.push(`/residentialArea/newCorrections/detail/${item.id}`),
      })}
      onTableChange={({ current }, _filters, sortData) => handleTableChange(current || 1, sortData)}
    />
  );
};
