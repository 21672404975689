import { useContext, useState } from 'react';
import { ApiClient } from '../../../services/api';
import { RatingsApiUrls, getAuthHeaders } from '../../../utils';
import { Rating } from '../../../types';
import { useTranslation, PandoraContext } from '@iib/pandora-box';

type Params = {
  id: string;
};

type HookReturnType = {
  performAction: (params: Params) => Promise<(Rating & { position: any }) | null>;
  loading: boolean;
};

export const useGetRating = (): HookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { messageApi } = useContext(PandoraContext);

  const performGetRating = async (params: Params): Promise<(Rating & { position: any }) | null> => {
    try {
      setLoading(true);
      const { data } = await ApiClient.get(RatingsApiUrls.getById(params.id), { headers: getAuthHeaders() });
      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
      messageApi?.error(t('api_error_generic'));
      return null;
    }
  };
  return { loading, performAction: performGetRating };
};
