import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserList } from './list';
import { UserDetail } from './detail';
import { UserCreate } from './create';
import * as S from './styles';

export default function Component(): JSX.Element {
  return (
    <S.Wrapper>
      <Switch>
        <Route path="/users" exact component={UserList} />
        <Route path="/users/detail/:id" component={UserDetail} />
        <Route path="/users/create" component={UserCreate} />
        <Route path="/users/edit/:id" component={UserCreate} />
      </Switch>
    </S.Wrapper>
  );
}
