import { useContext, useState } from 'react';
import { ApiClient } from '../../../services/api';
import { UserApiUrls, getAuthHeaders } from '../../../utils';
import { MaybePromise, User } from '../../../types';
import { useTranslation, PandoraContext } from '@iib/pandora-box';
import { AxiosError } from 'axios';

type Body = {
  givenName?: string;
  familyName?: string;
  email?: string;
  phoneNumber?: string;
  roles?: string[];
  customer?: number;
  customers?: number[];
  affiliate?: string;
  notes?: string;
};

type Props = {
  body: Body;
  onSuccess?: (updated: User) => MaybePromise<void>;
  onError?: (error: any) => MaybePromise<void>;
};
type HookReturnType = {
  performAction: (params: Props) => Promise<User | null>;
  loading: boolean;
};

export const useCreateUser = (): HookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { messageApi } = useContext(PandoraContext);

  const performCreateUser = async ({ body, onSuccess, onError }: Props): Promise<User | null> => {
    try {
      setLoading(true);
      const { data } = await ApiClient.post(UserApiUrls.create, body, { headers: getAuthHeaders() });
      setLoading(false);
      messageApi?.success(t('create_user_success'));
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error: unknown) {
      setLoading(false);

      if (error instanceof AxiosError) {
        const errorResponse = error.response?.data;
        const errorMessage = Array.isArray(errorResponse?.message) ? errorResponse.message.join(', ') : errorResponse?.message || 'Unknown error';
        const errorDetail = errorResponse?.error || 'Unknown error';
        const errorStatus = errorResponse?.statusCode || error.response?.status || 'Unknown status';

        const errorInfo = `${errorMessage} - ${errorDetail} ${errorStatus}`;
        messageApi?.error(`${errorInfo}`);
        if (onError) onError(error);
      } else {
        const errorInfo = JSON.stringify(error);
        messageApi?.error(`An unexpected error occurred: ${errorInfo}`);
      }

      return null;
    }
  };
  return { loading, performAction: performCreateUser };
};
