import { PALETTE, Button as PandoraButton } from '@iib/pandora-box';
import styled from 'styled-components';

type ButtonProps = {
  delete?: boolean;
};

export const Button = styled(PandoraButton)<ButtonProps>`
  ${(props) =>
    !props.delete
      ? ``
      : `
    background-color: ${PALETTE.COMMON.BERRY.MAIN};

    &:not([disabled]):hover {
        background-color: ${PALETTE.COMMON.BERRY.LIGHTER} !important;
      }
  `}
`;
