import { useContext, useState } from 'react';
import { ApiClient } from '../../../services/api';
import { CorrectionsApiUrls, getAuthHeaders } from '../../../utils';
import { Correction } from '../../../types';
import { useTranslation, PandoraContext } from '@iib/pandora-box';

type Params = {
  id: string;
  answer?: string;
};

type HookReturnType = {
  performAction: (params: Params) => Promise<(Correction & { position: any }) | null>;
  loading: boolean;
};

export const useUpdateAnswer = (): HookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { messageApi } = useContext(PandoraContext);

  const performAnswerUpdate = async (params: Params): Promise<(Correction & { position: any }) | null> => {
    try {
      setLoading(true);
      const { data } = await ApiClient.post(CorrectionsApiUrls.updateAnswerById(params.id), { answer: params.answer }, { headers: getAuthHeaders() });
      setLoading(false);
      messageApi?.success(t('correction_answer_updated'));
      return data;
    } catch (error) {
      setLoading(false);
      messageApi?.error(t('api_error_generic'));
      return null;
    }
  };
  return { loading, performAction: performAnswerUpdate };
};
