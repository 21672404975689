/* eslint-disable @typescript-eslint/no-unused-vars */
import { CheckOutlined } from '@ant-design/icons';
import { DetailLayout, confirm, useTranslation, Select, Input, AiFillExclamationCircle, MapMTK } from '@iib/pandora-box';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';

import moment from 'moment';
import { Correction } from '../../../types';
import { COLOR, CorrectionStatus, correctionStatusLabel, getEnv, MAP_STYLE } from '../../../utils';
import { useApproveCorrection, useDeleteCorrection, useGetCorrection } from '../hooks';
import * as S from './styles';
import { useUpdateAnswer } from '../hooks/update-correction-answer';

const MTK_API_KEY = getEnv('REACT_APP_MTK_API_KEY') || '';

export const NewCorrectionsDetail = (): ReactElement => {
  const {
    location: { pathname },
    push,
  } = useHistory();
  const id = pathname.split('/')[4];
  const [correctionItem, setCorrectionItem] = useState<Correction & { position: [number, number] }>();
  const { performAction: getCorrection, loading: loadingCorrection } = useGetCorrection();
  const { performAction: deleteCorrection } = useDeleteCorrection();
  const { performAction: approveCorrection } = useApproveCorrection();
  const { performAction: updateAnswer } = useUpdateAnswer();
  const { t } = useTranslation();

  const fetchCorrection = async () => {
    const fetchedCorrection = await getCorrection({ id });
    if (fetchedCorrection) {
      fetchedCorrection.position = [fetchedCorrection?.lng, fetchedCorrection?.lat];
      setCorrectionItem(fetchedCorrection);
    }
  };

  useEffect(() => {
    fetchCorrection();
  }, [id]);

  const showDeleteConfirm = (deleteId: number) => {
    confirm({
      title: t('delete_correction_heading'),
      icon: <AiFillExclamationCircle />,
      okType: 'danger',
      cancelText: t('cancel'),
      async onOk() {
        const response = await deleteCorrection({ id: String(deleteId) });
        if (!!response && 'affected' in response && response.affected === 1) push('/residentialArea/newCorrections');
      },
    });
  };

  const showApproveConfirm = (correctionId: number, status: keyof CorrectionStatus) => {
    confirm({
      title: t('update_correction_status'),
      icon: <AiFillExclamationCircle />,
      cancelText: t('cancel'),
      async onOk() {
        const response = await approveCorrection({ id: String(correctionId), correctedStatus: CorrectionStatus[status as keyof typeof CorrectionStatus] });
        if (!!response && 'affected' in response && response.affected === 1) push('/residentialArea/newCorrections');
      },
    });
  };

  const handleAnswerChange = (correctionId: number, answer: string) => {
    confirm({
      title: t('update_correction_answer'),
      icon: <AiFillExclamationCircle />,
      cancelText: t('cancel'),
      async onOk() {
        const response = await updateAnswer({ id: String(correctionId), answer });
        if (!!response && 'affected' in response && response.affected === 1) push('/residentialArea/newCorrections');
      },
    });
  };

  return (
    <>
      {correctionItem && (
        <DetailLayout
          width="100%"
          columns={2}
          loading={loadingCorrection}
          Loader={<Loading />}
          header={{
            title: t('correction_data'),
            showDelete: true,
            onDelete: () => showDeleteConfirm(correctionItem.id),
            rightButton: (
              <Select
                defaultValue={correctionItem.status}
                options={Object.keys(CorrectionStatus).map((key, value) => {
                  return { label: CorrectionStatus[key as keyof typeof CorrectionStatus], value: key };
                })}
                style={{
                  width: 120,
                }}
                onChange={(e) => showApproveConfirm(correctionItem.id, e)}
              />
            ),
          }}
          record={correctionItem}
          fields={{
            status: {
              span: 2,
              label: t('status'),
              order: 0,
              render: (status) => {
                let color = COLOR.YELLOW;

                if (status === 'gemeldet') color = COLOR.RED;
                else if (status === 'geprüft') color = COLOR.GREEN;

                return (
                  <S.StatusWrapper>
                    {correctionStatusLabel(status)}
                    &nbsp;
                    <S.Light color={color} />
                  </S.StatusWrapper>
                );
              },
            },
            comment: {
              span: 2,
              label: t('comment'),
              order: 1,
            },
            answer: {
              span: 2,
              label: t('answer'),
              order: 2,
              render: () => (
                <Input
                  defaultValue={correctionItem.answer}
                  addonAfter={
                    <CheckOutlined
                      onClick={() => {
                        handleAnswerChange(correctionItem?.id, correctionItem.answer);
                      }}
                    />
                  }
                  onChange={(e) => {
                    setCorrectionItem({ ...correctionItem, answer: e.target.value });
                  }}
                />
              ),
            },
            createdAt: {
              label: t('created_at'),
              order: 3,
              render: (createdAt) => <>{`${moment(createdAt).format('DD.MM.YYYY hh:mm')}${t('hour')}`}</>,
            },
            updatedAt: {
              label: t('updated_at'),
              order: 4,
              render: (updatedAt) => <>{`${moment(updatedAt).format('DD.MM.YYYY hh:mm')}${t('hour')}`}</>,
            },
            checkedAt: {
              span: 1,
              label: t('checked_at'),
              order: 5,
              render: (checkedAt) => <>{checkedAt ? `${moment(checkedAt).format('DD.MM.YYYY hh:mm')}${t('hour')}` : 'N/A'} </>,
            },
            checkedBy: {
              span: 1,
              label: t('checked_by'),
              order: 6,
              render: (checkedBy) => <>{checkedBy || 'N/A'}</>,
            },
            userEmail: {
              span: 2,
              label: t('email_c'),
              order: 7,
            },
            position: {
              span: 2,
              label: 'Map',
              order: 9,
              render: (value) => (
                <S.MapWrapper>
                  <MapMTK
                    mapId={'newCorrectionDetail'}
                    apiKey={MTK_API_KEY}
                    mapType={MAP_STYLE}
                    initialViewState={{ center: value, zoom: 16, pitch: 0, bearing: 0 }}
                    addMarkerAt={[value[0], value[1]]}
                  />
                </S.MapWrapper>
              ),
            },
          }}
          fieldsExtra={{
            wohnlagenkarte: {
              label: t('to_wohnlagenkarte'),
              span: 2,
              order: 8,
              render: () => (
                <a href={`${getEnv('REACT_APP_WOHNLAGENKARTE_URL')}/${correctionItem.lat}/${correctionItem.lng}/18`} target="_blank" rel="noopener noreferrer">
                  Lat: {correctionItem.lat}, Long: {correctionItem.lng}
                </a>
              ),
            },
          }}
        />
      )}
    </>
  );
};
